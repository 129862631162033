import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/industry.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"


const EcommerceIndustryPage = ({ data }) => (
    <Layout>
      <SEO
        title="Maintain Sales Flow with Odown’s Monitoring & Status Pages"
        description="Don't let website outages cost you customers and revenue. Odown's ecommerce website monitoring solution provides real-time visibility, alerting, and insights to keep your online store online and optimized."
        pathname={`/industry/ecommerce`}
        image={{
          src: "/general-image-og--odown_for_ecommerce.jpg",
          width: 1600,
          height: 800,
        }}
      />
      <HeadingSection />
      <PowerEcommerceSection />
      <KeyEventsSection imageData={data.FeatureBreakDownImage2.childImageSharp.fluid}/>
      <KnowFirstSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid} />
      <LightningSpeedSection imageData={data.FeatureBreakDownImage3.childImageSharp.fluid}/>
      <GetStartedBanner />
    </Layout>
  )

const HeadingSection = () => {
    const [getStartedButton, setGetStartedButton] = useState({
        name: "Get started for free",
        link: "https://app.odown.io/signup",
      });
    
     
  return (
    <div className="u-features ecommerce-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Monitoring solutions for today’s global online businesses
        </h1>
        <p className="hero_sub-title">
          Don’t be in the dark about your site's speed, availability, and functionality with Odown’s data-driven approaches to website monitoring. Track your e-commerce site’s status and get notified about potential issues.
        </p>
      </div>
      <div className="u-features__heading-btns center-button">
        <OutboundLink
          className="btn btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href={getStartedButton.link}
          target="_blank"
        >
          {getStartedButton.name}
        </OutboundLink>
      </div>
    
    </div>
  )
};

const PowerEcommerceSection = () => {
  return (
    <section className="u-main-feature power-ecommerce-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title1">
              Start powering your e-commerce with the best monitoring service available today
            </h2>
            <p className="u-main-feature_tc-desc2">
              Your online business has one goal — drive visitors towards purchasing your products. Being on top of your site performance and having the best monitoring to alert your team when something unexpected happens.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const KeyEventsSection = ({ imageData }) => {
    return (
      <section className="u-main-feature key-events-section">
        <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={imageData}
                alt="Industry - Keyevent for ecommerce"
              />
            </div>
          </div>
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              Monitor key events to keep customers coming back
            </h2>
            <p className="u-main-feature_tc-desc">
              Keep an eye on critical UX flows most often used by your site visitors. Test from 17 global checkpoints. Monitor performance over time. Deep dive into peak activity periods and identify customer pain points.
            </p>
          </div>
        </div>
        </div>
      </section>
    )
  }
  
  const KnowFirstSection = ({ imageData }) => {
    return (
      <section className="u-main-feature know-first-section">
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="u-main-feature_tc-title">
                Be the first to know that your e-commerce app is working correctly
              </h2>
              <div className="formatted-text">
                <p className="u-main-feature_tc-desc">
                  Knowing your online store and supporting API endpoints work as intended is vital.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Do your critical customer journeys work as intended?</strong> The service has optimal process flows to customer goals. Odown’s Web Monitoring can step through complex user actions and verify their function and speed.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Is your backend API responding as expected?</strong> If critical endpoints requests start failing, your team needs to know. Odown’s API Monitoring is the ultimate solution for monitoring your API responses.
                </p>
              </div>
            </div>
            <div className="u-main-feature__media-content">
              <div className="illustration-wrapper">
                <Img
                  fadeIn={false}
                  loading="eager"
                  fluid={imageData}
                  alt="Website monitoring - Lightning speed for SaaS"
                />
              </div>
            </div>
          </div>
          <div className="tableResponsive-industry">
            <div className="u-main-feature-table">
              <div className="u-main-feature-column">
                <h3>Monitor to stay competitive</h3>
                <p className="u-main-feature_tc-desc1">
                  Having high availability and a service faster than the competition is the perfect match. Odown can help you build out a baseline and make sure your product stays within those margins.
                </p>
              </div>
              <div className="u-main-feature-column">
                <h3>Not just a tool for production</h3>
                <p className="u-main-feature_tc-desc1">
                  Use Odown monitoring in your dev environment as well as in your acceptance environments to test before it goes out into production.
                </p>
              </div>
              <div className="u-main-feature-column">
                <h3>Monitoring with global checkpoints</h3>
                <p className="u-main-feature_tc-desc">
                  Just because your online store is operational in one country doesn’t guarantee it’s working everywhere. Monitor your service from 17 global checkpoints. Test from multiple locations simultaneously.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  


  const LightningSpeedSection = ({ imageData }) => {
    return (
      <section className="u-main-feature left-text">
        <div className="container">
          <div className="u-main-feature__wrapper">
          <div className="u-main-feature__media-content">
              <div className="illustration-wrapper">
                <Img
                  fadeIn={false}
                  loading="eager"
                  fluid={imageData}
                  alt="Industry - Lightning speed for ecommerce"
                />
              </div>
            </div>
            <div className="u-main-feature__text-content">
              <h2 className="u-main-feature_tc-title">
                Lightning speed is a must for any e-commerce business
              </h2>
              <div className="formatted-text">
                <p className="u-main-feature_tc-desc">
                  If your service is sluggish, you’re losing confidence with site visitors.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Drive a positive brand image.</strong> Use Odown to ensure customers get fast responses and load times. Page and API speed increases the perception of your brand and service.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Monitor latency from your customers' location.</strong> Network latency can change depending on localized internet provider changes. Take use of our access to regional datacenters around the world to monitor local uptime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  

  export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "product__api-monitoring__option__alerting.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    FeatureBreakDownImage2: file(
      relativePath: { eq: "docs/monitoring__create-monitor__2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
      FeatureBreakDownImage3: file(
      relativePath: { eq: "product__api-monitoring__section_long-uptime-monitoring-log.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`


export default EcommerceIndustryPage